import "./MyProfile.css";
import { useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../../Super_admin/BaseUrl";
import { useState } from "react";
import QRCode from "qrcode";
import { usePDF } from "react-to-pdf";
import MyPDF from "./PDFViewer";
// import { PDFViewer } from "@react-pdf/renderer";
import userImg from "../../assets/profilImg.jpg";
import {
  Document,
  Page,
  View,
  Text,
  PDFViewer,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

function MyProfile() {
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [userInfo, setUserInfo] = useState([]);
  const [imgUri, setImgUri] = useState("");

  useEffect(() => {
    axios
      .get(
        BaseUrl + "/api/admin/users/show/" + sessionStorage.getItem("userId"),
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(async (res) => {
        console.log(res.data.user);
        setUserInfo(res.data.user);
        const imgdata = await QRCode.toDataURL(`${"00" + res.data.user?.id}`);
        setImgUri(imgdata);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // /////////////////////////////////
  const styles = StyleSheet.create({
    img_div: {
      width: 420,
      height: 240,
      backgroundColor: "#3f48cc",
    },
    img_main: {
      padding: "20",
      margin: "0 auto",
      width: 200,
      backgroundColor: "#121b9b",
    },
    user_img: {
      margin: "0 auto",
      width: 150,
      border: "solid",
    },
    line: {
      marginTop: 5,
      height: 5,
      backgroundColor: "#3f48cc",
    },
    qrImg:{
      padding:10
    },
    user_title: {
      marginTop: 5,
      margin: "0 auto",
      color:'blue'
    },

    hemis_title: {
      paddingTop: 10,
      margin: "0 auto",
    },
    footerr: {
      marginTop: 5,
      width: 420,
      height:41,
      backgroundColor: "#3f48cc",
    },
    link:{
      textAlign:'center',
      padding:5,
      color:'white'
    }
  });

  return (
    <div className="container ">
      <PDFViewer style={styles.document1} height={1200} width={1200}>
        <Document>
          <Page size="A5" style={styles.page1}>
            <View style={styles.img_div}>
              <View style={styles.img_main}>
                {userInfo.rasm === undefined || userInfo.rasm === null ? (
                  <Image src={{ uri: userImg }} alt="rasm yuklanmagan" />
                ) : (
                  <Image
                    style={styles.user_img}
                    src={{ uri: BaseUrl + "/storage/" + userInfo.rasm }}
                  />
                )}
              </View>
            </View>
            <View style={styles.line}> </View>
            <View style={styles.line}> </View>
            <View style={styles.qrImg}>
              <Image style={styles.user_img} src={{ uri: imgUri }} />
              <Text style={styles.user_title}>
                {userInfo && userInfo.lastname + " " + userInfo.firstname}
              </Text>
              <Text style={styles.user_title}>{userInfo.middlename}</Text>
              <Text style={styles.hemis_title}>
                HEMIS ID:{userInfo && userInfo.hemis_id}
              </Text>
              <Text style={styles.hemis_title}>
                ARM ID: {userInfo && userInfo.id}
              </Text>
            </View>
            <View style={styles.line}> </View>
            <View style={styles.line}> </View>
            <View style={styles.footerr}>
              <Text style={styles.link}>WWW.AK.ADU.UZ</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}

export default MyProfile;
